// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("/opt/build/repo/src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-leadrx-js": () => import("/opt/build/repo/src/pages/leadrx.js" /* webpackChunkName: "component---src-pages-leadrx-js" */),
  "component---src-pages-mycredit-js": () => import("/opt/build/repo/src/pages/mycredit.js" /* webpackChunkName: "component---src-pages-mycredit-js" */),
  "component---src-pages-privileged-js": () => import("/opt/build/repo/src/pages/privileged.js" /* webpackChunkName: "component---src-pages-privileged-js" */),
  "component---src-pages-roar-js": () => import("/opt/build/repo/src/pages/roar.js" /* webpackChunkName: "component---src-pages-roar-js" */),
  "component---src-pages-signal-js": () => import("/opt/build/repo/src/pages/signal.js" /* webpackChunkName: "component---src-pages-signal-js" */),
  "component---src-pages-skype-js": () => import("/opt/build/repo/src/pages/skype.js" /* webpackChunkName: "component---src-pages-skype-js" */),
  "component---src-pages-theswitch-js": () => import("/opt/build/repo/src/pages/theswitch.js" /* webpackChunkName: "component---src-pages-theswitch-js" */),
  "component---src-pages-winebox-js": () => import("/opt/build/repo/src/pages/winebox.js" /* webpackChunkName: "component---src-pages-winebox-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/opt/build/repo/.cache/data.json")

